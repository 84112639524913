import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Footer from '../components/Footer';
import SEO from '../components/SEO';

const TosStyles = styled.div`
  @mixin break($p, $b: 'max-width') {
    @media screen and ($b: $p + px) {
      @content;
    }
  }
  #tos {
    // height: 100vh;
    position: relative;
    display: block;
    padding: 40px 0 120px;
    background-color: var(--white);
    h2,
    h6 {
      text-align: center;
      @media screen and (max-width: 650px) {
        font-size: 38px;
      }
    }
    h6 {
      font-size: 2.5rem;
      line-height: 1.2;
      max-width: 900px;
      margin: 40px auto 20px;
      text-align: left;
      color: #474747;
    }
    p {
      text-align: left;
      max-width: 900px;
      margin: 0 auto;
      &:last-of-type {
        margin-bottom: 25px;
      }
      @media screen and (max-width: 650px) {
        font-size: 18px;
      }
    }
    @media screen and (max-width: 768px) {
      display: block;
    }
    @media screen and (max-width: 650px) {
      padding: 30px 0 70px;
    }
    a {
      font-size: 2rem;
      border-bottom: 1px solid #474747;
      @media screen and (max-width: 650px) {
        font-size: 18px;
      }
    }
    .to-home {
      text-align: center;
      display: block;
      padding: 20px 0 0;
      a.btn {
        font-size: 2.2rem;
        color: white;
        border: 0;
        padding: 15px 20px;
        background: var(--gold);
        @media screen and (max-width: 650px) {
          /* font-size: 18px; */
        }
      }
    }
  }
`;

export default function TermsOfUsePage() {
  return (
    <>
      <SEO title="SGM | Terms of Use" />
      <TosStyles>
        <section id="tos">
          <div className="wrap">
            <h2>Terms of Use</h2>

            <p>
              Welcome to Stephen Gerber Music! Our goal is to find the right
              stuff for you by blogging about or by ranking top brands in the
              world ("The Service").These Terms of Use ("Terms") outline the
              rules and regulations for the use of Stephen Gerber Music
              Website.By accessing this website we assume you accept these terms
              and conditions. Do not continue to use Stephen Gerber Music if you
              do not agree to take all of the terms and conditions stated on
              this page.The following terminology applies to these Terms of Use,
              Privacy Policy, Disclaimers on this website, and all other
              agreements. "Client", "You" and "Your" refers to you, the person,
              visiting (or logging onto this website and compliant to the
              Company’s terms and conditions. "The Company", "Ourselves", "We",
              "Our" and "Us", refers to our Company. "Party", "Parties", or
              "Us", refers to both the Client and ourselves.All updates to Our
              Terms are viewable on this page. You are bound by any updates and
              changes we make to Our Terms.
            </p>

            <h6>Intellectual Property</h6>

            <p>
              Unless otherwise stated, Stephen Gerber Music and/or its licensors
              own the intellectual property rights for all material on Stephen
              Gerber Music. All intellectual property rights are reserved. You
              may access this from Stephen Gerber Music for your own personal
              use subjected to restrictions set in these terms and
              conditions.You must not: - Republish material from Stephen Gerber
              Music - Sell, rent or sub-license material from Stephen Gerber
              Music - Reproduce, duplicate or copy material from Stephen Gerber
              Music - Redistribute content from Stephen Gerber Music
            </p>

            <h6>iFrames</h6>

            <p>
              Without prior approval and written permission, you may not create
              frames around our Web Pages that in any way alter in any way the
              visual presentation or appearance of our Website.
            </p>

            <h6>Content Liability</h6>

            <p>
              Stephen Gerber Music (or any of its owners, employees, or agents)
              will not be liable to You for damages. Including incidental,
              indirect, special, punitive, and/or consequential damages,
              originating from or in connection to your use with Stephen Gerber
              Music and/or service (or any content included on Our site). Making
              online purchases is very intimate and subjective.
            </p>

            <h6>Websites &amp; Links</h6>

            <p>
              Portions of the Site involve linking to websites belong to third
              parties. The Site may also provide you with links to access the
              websites of third party vendors or retailers. We have no control
              over third-party sites, and all use of third-party sites is at
              your own risk. Additionally, we cannot accept responsibility for
              any payments processed or submitted through such third party
              sites, or for the privacy policies of any such sites. We are not
              responsible for content available by means of such sites. We do
              not endorse any products offered by third parties and we urge our
              users to exercise caution in using third-party sites.
            </p>

            <h6>Disclaimers and Disclaimer of Warranty</h6>

            <p>
              Stephen Gerber Music attempts to be as accurate as possible.
              Reviewed items frequently improve &amp; upgrade and specifications
              change. HOWEVER, WE DO NOT WARRANT THAT ANY ESTIMATION OR
              DESCRIPTION PROVIDED THROUGH THE SERVICE IS ACCURATE, COMPLETE,
              RELIABLE, CURRENT, OR ERROR-FREE. WE CANNOT AND DO NOT ACCEPT ANY
              LIABILITY FOR ANY RELIANCE BY YOU ON SUCH INFORMATION. We reserve
              the right to correct any errors, inaccuracies or omissions and to
              change or update the content at any time without prior notice
              (including after you have submitted an application to us). If you
              choose to rely on information from the Site or Service, you do so
              solely at your own risk. Some states or jurisdictions do not allow
              the exclusion of certain warranties. Accordingly, some of the
              above exclusions may not apply to you.
            </p>

            <h6>Indemnification</h6>

            <p>
              You agree to indemnify, defend, and hold harmless Stephen Gerber
              Music and its respective employees, directors &amp; agents of
              each, against any and all claims, damages, or costs or expenses
              (including court costs and attorneys’ fees) that arise directly or
              indirectly from: (a) breach of these Terms by you; (b) any claim,
              loss or damage experienced from your use or attempted use of (or
              inability to use) the Site and/or Service, including any
              transactions that you conduct or attempt to conduct; (c) your
              violation of any law or regulation; (d) your infringement of any
              right of any third party; and (e) any other matter for which you
              are responsible hereunder or under law. You agree that your use of
              the Site and/or Service, including, without limitation, provision
              of service in connection with the Site shall be in compliance with
              all applicable laws, regulations and guidelines.
            </p>

            <h6>Privacy</h6>

            <p>
              We are committed to the protection of our users privacy. please
              review our Privacy Policy to read our privacy practices.
            </p>

            <h6>Miscellaneous</h6>

            <p>
              These Terms shall be governed by and interpreted in accordance
              with the law of the United States of America exclusive of its
              choice of law rules. Your conduct may also be subject to other
              local, state, and national laws. Any action to be brought in
              connection with these Terms or the Application shall be brought
              exclusively in the courts of the US and you irrevocably consent to
              their jurisdiction. Any cause of action against Stephen Gerber
              Music must be brought within one (1) year of the date such cause
              of action arose. In the event that any provision of these Terms is
              held to be unenforceable, such provision shall be replaced with an
              enforceable provision which most closely achieves the effect of
              the original provision, and the remaining terms of these Terms
              shall remain in full force and effect. Nothing in these Terms
              creates any agency, employment, joint venture, or partnership
              relationship between you and Stephen Gerber Music or authorizes
              you to act on behalf of Stephen Gerber Music. Except as may be
              expressly stated in these Terms, these Terms constitute the entire
              agreement between Stephen Gerber Music and you pertaining to the
              subject matter hereof, and any and all other agreements existing
              between us relating thereto are hereby cancelled. We may assign
              our rights and obligations hereunder to any third party without
              prior notice. You shall not assign any of your rights or
              obligations hereunder, and any assignment in violation of the
              foregoing shall be void. No waiver of any breach or default
              hereunder shall be deemed to be a waiver of any preceding or
              subsequent breach or default.
            </p>
          </div>
          <div className="to-home">
            <Link className="btn" to="/">
              Return Home
            </Link>
          </div>
        </section>
      </TosStyles>

      <Footer />
    </>
  );
}
